<template>
  <confirmation-popup-template :onConfirm="onConfirm.bind(this, newValue)" :onCancel="onCancel" :title="title">
    <input type="text" v-model="newValue" />
  </confirmation-popup-template>
</template>

<script>
import Vue from 'vue'

import ConfirmationPopupTemplate from '@/components/ConfirmationPopupTemplate'

const ConfirmationPopup = {
  name: 'ConfirmationPopup',
  components: {
    ConfirmationPopupTemplate,
  },
  data() {
    return {
      newValue: 0,
    }
  },
  props: {
    initialValue: String,
    onConfirm: Function,
    onCancel: Function,
    title: String,
  },
  mounted() {
    this.newValue = this.initialValue
  },
}

export const openChangeStringValuePopup = ({ initialValue, title }) => {
  return new Promise((resolve, reject) => {
    const ConfirmationPopupClass = Vue.extend(ConfirmationPopup)
    const instance = new ConfirmationPopupClass({
      propsData: {
        initialValue,
        onConfirm: newValue => {
          instance.$destroy()
          return resolve(newValue)
        },
        onCancel: () => {
          instance.$destroy()
          return reject()
        },
        title,
      },
    })
    instance.$mount()
  })
}

export default ConfirmationPopup
</script>

<style lang="sass" scoped>
input
  font-size: 14px
  line-height: 20px
  padding: 6px
  padding-left: 12px
  width: 100%
  border: none
  outline: none
  border-bottom: 3px solid #0d87ff
  border-radius: 2px
  background-color: #ecf1fa
</style>
