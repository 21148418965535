<template>
  <div class="user-quota">
    <properties-table v-if="user.id" :object="quota" title="Quota" :fields="fields">
      <template #actions="{ propKey }">
        <action-list v-role="'QUOTA_OVERRIDE'">
          <action v-if="!isQuotaEndless(propKey) || isSuperAdmin" @click="onEditClick(propKey)">Edit</action>
          <action v-if="hasPersonalQuota(propKey)" @click="onResetClick(propKey)">Reset</action>
        </action-list>
      </template>
    </properties-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Action from '@/components/Action'
import ActionList from '@/components/ActionList'
import PropertiesTable from '@/components/PropertiesTable'
import { openChangeNumberValuePopup } from '@/components/ChangeNumberValuePopup'

export default {
  name: 'UserQuota',
  components: {
    Action,
    ActionList,
    PropertiesTable,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      fields: {
        Coverset: { title: 'Custom Coversets' },
        File: { title: 'File Storage' },
        Project: { title: 'Projects' },
        SitemapPage: { title: 'SitemapPages' },
        Userflow: { title: 'Userflows' },
        Workspace: { title: 'Workspaces' },
        WorkspaceMember: { title: 'Workspace Members' },
      },
    }
  },
  computed: {
    ...mapState('user', ['permissions']),
    isSuperAdmin() {
      return this.permissions.includes('SUPERADMIN')
    },
    quota() {
      return Object.fromEntries(
        Object.keys(this.user?.quota.max || {})
          .map(quotaID => {
            const used = this.getQuotaUsedByQuotaId(quotaID)
            const personallyAvailable = this.getPersonalQuotaAvailableByQuotaId(quotaID)
            const available = this.getQuotaAvailableByQuotaId(quotaID)

            return [quotaID, `${used} / ${personallyAvailable || available}`]
          }),
      )
    },
  },
  created() {},
  methods: {
    ...mapActions('user', ['resetPersonalQuota', 'setPersonalQuota']),
    hasPersonalQuota(quotaId) {
      return !!this.getPersonalQuotaAvailableByQuotaId(quotaId)
    },
    isQuotaEndless(quotaId) {
      return this.user.quota.max[quotaId] === -1
    },
    getPersonalQuotaAvailableByQuotaId(quotaId) {
      const value = this.user.quota.overrides[quotaId] || 0
      return value === -1 ? '∞' : value
    },
    getQuotaAvailableByQuotaId(quotaId) {
      let value = this.user.quota.max[quotaId] || 0
      if (quotaId === 'File') {
        value = `${Math.round(value / (1024 * 1024 * 1024))} GB`
      }
      return value === -1 ? '∞' : value
    },
    getQuotaUsedByQuotaId(quotaId) {
      let value = this.user.quota.used[quotaId] || 0
      if (quotaId === 'File') {
        value = `${Math.round((value / 1024 / 1024) * 100) / 100} GB`
      }
      return value
    },
    onEditClick(quotaId) {
      const initialValue = this.getPersonalQuotaAvailableByQuotaId(quotaId) || this.getQuotaAvailableByQuotaId(quotaId)
      const quotaText = this.fields[quotaId].title
      openChangeNumberValuePopup({ title: `Personal ${quotaText} quota for ${this.user.email}`, initialValue })
        .then(async value => {
          await this.setPersonalQuota({ userId: this.user.id, quotaId, value })
          this.$emit('updateRequired')
        })
        .catch(() => {})
    },
    async onResetClick(quotaId) {
      await this.resetPersonalQuota({ userId: this.user.id, quotaId })
      this.$emit('updateRequired')
    },
  },
}
</script>
