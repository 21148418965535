<template>
  <div class="user-properties">
    <properties-table v-if="user.id" title="General" :object="user" :fields="fields">
      <template #fieldAction="{ fieldName }">
        <action v-if="fieldName === 'email'" @click="openChangeStringValuePopup">Change</action>
        <action
          v-if="fieldName === 'paymentSystemUserId' && !user.paymentSystemUserId"
          @click="openCreateFSAccountPopup"
        >
          Create FS Account
        </action>
        <action v-if="fieldName === 'updatedAt'" @click="openResetPasswordPopup">Reset password</action>
      </template>
    </properties-table>
  </div>
</template>

<script>
import axios from 'axios'

import Action from '@/components/Action'
import { openConfirmationPopup } from '@/components/ConfirmationPopup'
import { openChangeStringValuePopup } from '@/components/ChangeStringValuePopup'
import { openCreateFSAccountPopup } from '@/components/CreateFSAccountPopup'
import PropertiesTable from '@/components/PropertiesTable'

import formatDate from '@/utils/formatDate'

export default {
  name: 'UserProperties',
  components: {
    Action,
    PropertiesTable,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      fields: {
        id: { title: 'ID' },
        email: { title: 'Email' },
        firstName: { title: 'First Name' },
        lastName: { title: 'Last Name' },
        country: { title: 'Country' },
        paymentSystemUserId: { title: 'Billing Account', processLink: () => this.accountLink, target: '_blank' },
        createdAt: { title: 'Created', processValue: formatDate },
        updatedAt: { title: 'Last Login', processValue: formatDate },
        deletedAt: { title: 'isActive', isBoolean: true, processValue: () => !this.user.deletedAt },
      },
    }
  },
  computed: {
    accountLink() {
      return `https://app.fastspring.com/2/store/customer.xml?id=${this.user.paymentSystemUserId}`
    },
  },
  created() {},
  methods: {
    async openChangeStringValuePopup() {
      openChangeStringValuePopup({
        title: 'Change user email',
        initialValue: this.user.email,
      })
        .then(async value => {
          await axios.post(
            `${process.env.VUE_APP_APP_BACKEND_URL}/support/change-email`,
            { id: this.user.id, email: value },
            { withCredentials: true },
          )
          this.$emit('change')
        })
        .catch(() => {})
    },
    openCreateFSAccountPopup: function () {
      openCreateFSAccountPopup(this.user)
        .then(() => {
          this.$emit('change')
        })
        .catch(e => {
          if (e) {
            openConfirmationPopup({
              text: e.message,
            })
          }
        })
    },
    openResetPasswordPopup: function () {
      openConfirmationPopup({
        text: 'Are you sure you want to reset user password',
      })
        .then(async () => {
          try {
            const { data } = await axios.post(
              `${process.env.VUE_APP_APP_BACKEND_URL}/support/reset-password`,
              { email: this.user.email },
              { withCredentials: true },
            )
            openConfirmationPopup({
              text: data,
            })
          } catch (e) {
            openConfirmationPopup({
              text: e.message,
            })
          }
        })
        .catch(e => {
          if (e) {
            openConfirmationPopup({
              text: e.message,
            })
          }
        })
    },
  },
}
</script>
