<template>
  <div class="loader">
    <div class="container" :style="containerStyle">
      <div class="division">
        <div class="dot" />
      </div>
      <div class="division">
        <div class="dot" />
      </div>
      <div class="division">
        <div class="dot" />
      </div>
      <div class="division">
        <div class="dot" />
      </div>
      <div class="division">
        <div class="dot" />
      </div>
      <div class="division">
        <div class="dot" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Loader',
  components: {},
  props: {
    size: Number,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState([]),
    containerStyle() {
      return { width: this.size ? `${this.size}px` : '100px', height: this.size ? `${this.size}px` : '100px' }
    },
  },
  created() {},
  methods: {
    ...mapActions([]),
  },
}
</script>

<style lang="sass" scoped>
.loader
  display: flex
  padding-top: 20px
  padding-bottom: 20px
  width: 100%
  justify-content: center

  .container
    position: relative

    .division
      position: absolute
      left: 45%
      width: 10%
      height: 100%
      animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite

      &:nth-child(1)
        animation-delay: 0.036s

      &:nth-child(2)
        animation-delay: 0.072s
        .dot
          transform: rotate(-15deg)

      &:nth-child(3)
        animation-delay: 0.108s
        .dot
          transform: rotate(-30deg)

      &:nth-child(4)
        animation-delay: 0.144s
        .dot
          transform: rotate(-45deg)

      &:nth-child(5)
        animation-delay: 0.18s
        .dot
          transform: rotate(-60deg)

      &:nth-child(6)
        animation-delay: 0.216s
        .dot
          transform: rotate(-75deg)

      .dot
        width: 100%
        height: 100%

        &:after
          content: ''
          position: absolute
          width: 100%
          height: 10%
          border-radius: 100%
          background-color: #0d87ff

@keyframes loader
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)
</style>
