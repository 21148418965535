<template>
  <div class="user-plan-actions">
    <action-list v-if="user.id" v-role="'TRIALS'" title="Give Trial" collapsed>
      <action v-for="tariff of filteredTariffs" :key="tariff.tariffID" @click="changePlan(tariff)">
        {{ tariff.tariffID }}
      </action>
    </action-list>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import axios from '@/utils/axios'

import Action from '@/components/Action'
import ActionList from '@/components/ActionList'
import { openConfirmationPopup } from '@/components/ConfirmationPopup'

export default {
  name: 'UserPlanActions',
  components: {
    Action,
    ActionList,
  },
  props: {
    user: Object,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('tariffs', ['tariffs']),
    filteredTariffs() {
      return this.tariffs.filter(
        ({ tariffID: tariffId }) => !['free'].includes(tariffId) && !tariffId.match('year') && !tariffId.match(/\d/),
      )
    },
  },
  created() {
    this.getTariffs()
  },
  methods: {
    ...mapActions('tariffs', ['getTariffs']),
    changePlan(tariff) {
      openConfirmationPopup({
        text: `Are you sure you want to give user ${this.user.email} trial for plan "${tariff.displayName}"?`,
      })
        .then(async () => {
          const { id } = this.$route.params
          await axios.post(`/support/users/${id}/changePlan`, { tariffId: tariff.tariffID })
          this.$emit('change')
        })
        .catch(() => {})
    },
  },
}
</script>
