<template>
  <div class="project-users">
    <Table
      v-if="projectUsers.length"
      title="Users with access"
      :rows="projectUsers"
      :fields="fields"
      @rowClick="onUserRowClick"
    />
  </div>
</template>

<script>
import Table from '@/components/Table'

export default {
  name: 'ProjectUsers',
  components: {
    Table,
  },
  props: {
    project: Object,
  },
  data() {
    return {
      fields: {
        email: { title: 'Email' },
        role: { title: 'Role' },
      },
    }
  },
  computed: {
    projectUsers() {
      const collaborators = this.project.collaborators || []
      const workspaceMembers = this.project.workspaceMembers || []
      return [...collaborators, ...workspaceMembers]
    },
  },
  created() {},
  methods: {
    onUserRowClick(row) {
      this.$router.push({ name: 'SupportUser', params: { id: row.id } })
    },
  },
}
</script>
