<template>
  <div class="search-input">
    <input v-model="query" :placeholder="placeholder" />
    <icon class="icon" icon="Icons/search" />
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    debounce: String,
    placeholder: String,
  },
  data() {
    return {
      query: '',
      queryDebounced: '',
      queryTimeout: null,
    }
  },
  computed: {
    debounceNumber() {
      return parseInt(this.debounce) || 200
    },
  },
  created() {
    this.query = localStorage.getItem(`search['${this.$route.name}']`) || ''
  },
  watch: {
    query() {
      clearTimeout(this.queryTimeout)
      this.queryTimeout = setTimeout(() => {
        this.queryDebounced = this.query
      }, this.debounceNumber)
    },
    queryDebounced() {
      localStorage.setItem(`search['${this.$route.name}']`, this.query)
      this.$emit('change', this.queryDebounced)
    },
  },
}
</script>

<style lang="sass" scoped>
.search-input
  position: relative

  input
    font-size: 14px
    line-height: 20px
    padding: 6px
    padding-left: 12px
    width: 100%
    border: none
    outline: none
    border-bottom: 3px solid #0d87ff
    border-radius: 2px
    background-color: #ecf1fa

  .icon
    position: absolute
    right: 8px
    top: 4px
    fill: #0d87ff
</style>
