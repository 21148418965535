<template>
  <popup :isVisible="true" @close="onCancel">
    <div class="confirmation-popup">
      <h3 class="title" v-if="title">{{ title }}</h3>
      <slot />
      <div class="buttons">
        <action @click="onConfirm">CONFIRM</action>
        <action @click="onCancel">CANCEL</action>
      </div>
    </div>
  </popup>
</template>

<script>
import Action from '@/components/Action'
import Popup from '@/components/Popup'

export default {
  name: 'ConfirmationPopup',
  components: {
    Action,
    Popup,
  },
  props: {
    onConfirm: Function,
    onCancel: Function,
    title: String,
  },
}
</script>

<style lang="sass" scoped>
.confirmation-popup
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 100%

  .buttons
    display: flex
    justify-content: space-between

.title
  margin: 0
</style>
