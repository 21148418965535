<template>
  <div class="user-projects">
    <Table
      v-if="user.collaborationProjects"
      title="Projects where user is invited"
      :rows="user.collaborationProjects"
      :fields="fields"
      @rowClick="onRowClick"
      collapsed
    />
  </div>
</template>

<script>
import Table from '@/components/Table'

export default {
  name: 'UserCollaborationProjects',
  components: {
    Table,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      fields: {
        title: { title: 'Title' },
        isArchived: { title: 'Archived', isBoolean: true, width: '90px' },
        isActive: { title: 'Active', isBoolean: true, width: '90px' },
        workspace: { title: 'Workspace', width: '300px' },
      },
    }
  },
  methods: {
    onRowClick(project) {
      this.$emit('rowClick', project)
    },
  },
}
</script>
