<template>
  <div class="user-projects">
    <Table
      v-if="user.projects"
      title="User's Projects"
      :rows="user.projects"
      :fields="fields"
      @rowClick="onRowClick"
      collapsed
    />
  </div>
</template>

<script>
import Table from '@/components/Table'

import formatDate from '@/utils/formatDate'

export default {
  name: 'UserProjects',
  components: {
    Table,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      fields: {
        title: { title: 'Title' },
        updatedAt: { title: 'Updated', processValue: formatDate, width: '220px' },
        deletedAt: { title: 'Active', isBoolean: true, processValue: value => value === null, width: '90px' },
        isArchived: { title: 'Archived', isBoolean: true, width: '90px' },
        workspace: { title: 'Workspace', width: '200px' },
      },
    }
  },
  methods: {
    onRowClick(project) {
      this.$emit('rowClick', project)
    },
  },
}
</script>
