<template>
  <popup :isVisible="true" @close="onCancel">
    <loader v-if="isLoading" />
    <div class="popup" v-else>
      <input :value="email" placeholder="Email" disabled="true" />
      <input v-model="user.firstName" placeholder="First Name" />
      <input v-model="user.lastName" placeholder="Last Name" />
      <input v-model="user.country" placeholder="Country (ISO 3166-1 alpha-2) (two letters)" />
      <div class="buttons">
        <action
          @click="
            isLoading = true
            onConfirm(user)
          "
          >CONFIRM</action
        >
        <action @click="onCancel">CANCEL</action>
      </div>
    </div>
  </popup>
</template>

<script>
import Vue from 'vue'

import Action from '@/components/Action'
import Loader from '@/components/Loader'
import Popup from '@/components/Popup'

import axios from 'axios'

const CreateFSAccount = {
  name: 'CreateFSAccount',
  components: {
    Action,
    Loader,
    Popup,
  },
  props: {
    onConfirm: Function,
    onCancel: Function,
    id: String,
    email: String,
    initialFirstName: String,
    initialLastName: String,
    initialCountry: String,
  },
  data() {
    return {
      isLoading: false,
      user: {},
    }
  },
  created() {
    this.user = {
      id: this.id,
      email: this.email,
      firstName: this.initialFirstName,
      lastName: this.initialLastName,
      country: this.initialCountry,
    }
  },
}

export const openCreateFSAccountPopup = ({ id, email, firstName, lastName, country }) => {
  return new Promise((resolve, reject) => {
    const CreateFSAccountClass = Vue.extend(CreateFSAccount)
    const instance = new CreateFSAccountClass({
      propsData: {
        id,
        email,
        initialFirstName: firstName,
        initialCountry: country,
        initialLastName: lastName,
        onConfirm: async user => {
          try {
            await axios.post(`${process.env.VUE_APP_APP_BACKEND_URL}/support/create-fs-account`, user, {
              withCredentials: true,
            })
            instance.$destroy()
            return resolve()
          } catch (e) {
            instance.$destroy()
            return reject(e)
          }
        },
        onCancel: () => {
          instance.$destroy()
          return reject()
        },
      },
    })
    instance.$mount()
  })
}

export default CreateFSAccount
</script>

<style lang="sass" scoped>
.popup
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 100%

  .buttons
    display: flex
    justify-content: space-between
</style>
