<template>
  <tr class="row" @click="$emit('click')">
    <table-cell
      v-for="(field, fieldName) of fields"
      :key="row.id + fieldName"
      :field="field"
      :fieldName="fieldName"
      :row="row"
    />
    <div class="actions">
      <slot name="rowActions" :row="row" />
    </div>
  </tr>
</template>

<script>
import TableCell from '@/components/TableCell'

export default {
  name: 'TableRow',
  components: { TableCell },
  props: {
    title: String,
    row: Object,
    fields: Object,
  },
  methods: {
    value(field, fieldName) {
      const rawValue = this.row[fieldName]
      return field.processValue?.(rawValue) || rawValue
    },
  },
}
</script>

<style lang="sass" scoped>
.row
  position: relative
  cursor: pointer
  height: 72px
  background-color: #fff

  &:nth-of-type(2n)
    background-color: #f3f6fc

  &:hover
    .actions
      display: flex

.actions
  display: none
  justify-content: center
  align-items: center
  position: absolute
  height: 100%
  top: 0
  right: 20px
</style>
