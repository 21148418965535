<template>
  <div class="properties-table">
    <div class="title" v-if="title">{{ title }}</div>
    <table>
      <properties-table-row
        v-for="(field, fieldName) of fields"
        :key="fieldName"
        :object="object"
        :field="field"
        :fieldName="fieldName"
      >
        <template #actions="{ propKey, propValue }">
          <slot name="fieldAction" :fieldName="fieldName" />
          <slot name="actions" :propKey="propKey" :propValue="propValue" />
        </template>
      </properties-table-row>
    </table>
  </div>
</template>

<script>
import PropertiesTableRow from '@/components/PropertiesTableRow'

export default {
  name: 'PropertiesTable',
  components: { PropertiesTableRow },
  props: {
    fields: Object,
    object: Object,
    title: String,
  },
}
</script>

<style lang="sass" scoped>
.properties-table
  width: 100%
  font-size: 14px
  border-radius: 2px

.title
  font-size: 18px
  padding: 20px
</style>
