<template>
  <page title="Users">
    <template #actions>
      <icon v-if="!cacheBusted" class="refresh" @click="onBustCacheClick" icon="Icons/rotate" :size="18" />
    </template>
    <template #default>
      <div class="users-page">
        <search-input class="search" debounce="200" @change="onQueryChange" placeholder="Search by id, name, email" />
        <Table :rows="userRows" :fields="fields" @rowClick="onRowClick" />
      </div>
    </template>
  </page>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import SearchInput from '@/components/SearchInput'
import Table from '@/components/Table'

export default {
  name: 'SupportUsers',
  components: {
    SearchInput,
    Table,
  },
  data() {
    return {
      cacheBusted: false,
      query: '',
      fields: {
        email: { title: 'Email' },
        subscriptionsCnt: { title: 'Subscriptions', width: '140px' },
        firstName: { title: 'First Name', width: '200px' },
        lastName: { title: 'Last Name', width: '200px' },
      },
    }
  },
  computed: {
    ...mapState('users', ['users']),
    normalizedQuery() {
      return this.query.toLowerCase()
    },
    normalizedUsers() {
      return this.users.all.map(user => ({
        ...user,
        haystack: `${user.email} ${user.id} ${user.firstName} ${user.lastName}`,
      }))
    },
    filteredUsers() {
      return this.normalizedUsers.filter(row => row.haystack.match(this.normalizedQuery))
    },
    userRows() {
      // eslint-disable-next-line no-unused-vars
      return this.filteredUsers.map(({ haystack, ...rest }) => rest)
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    ...mapActions('users', ['getUsers']),
    async onRowClick(row) {
      this.$router.push({ name: 'SupportUser', params: { id: row.id } })
    },
    onQueryChange(value) {
      this.query = value
    },
    onBustCacheClick() {
      this.cacheBusted = true
      this.getUsers({ bustCache: true })
    },
  },
}
</script>

<style lang="sass" scoped>
.refresh
  cursor: pointer

  &:hover
    fill: #0d87ff

.search
  margin-bottom: 32px
</style>
