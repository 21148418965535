<template>
  <td class="cell" :style="{ width: field.width || '100%' }" :key="row.id + fieldName" :data-column="fieldName">
    <a :target="field.target || ''" v-if="field.processLink" :href="field.processLink(rawValue)">
      <span>
        {{ value }}
      </span>
    </a>
    <input v-else-if="field.isBoolean" type="checkbox" :checked="value" disabled />
    <span v-else>
      {{ value }}
    </span>
  </td>
</template>

<script>
export default {
  name: 'TableCell',
  props: {
    field: Object,
    fieldName: String,
    row: Object,
  },
  computed: {
    rawValue() {
      return this.row[this.fieldName]
    },
    value() {
      return this.field.processValue ? this.field.processValue(this.rawValue) : this.rawValue
    },
  },
}
</script>

<style lang="sass" scoped>
.cell
  padding: 0 24px

  a
    text-decoration: none
    color: #0d87ff
</style>
