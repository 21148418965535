<template>
  <div class="user-projects">
    <Table v-if="user.invoices" title="User's Invoices" :rows="user.invoices" :fields="fields" collapsed />
  </div>
</template>

<script>
import Table from '@/components/Table'

import formatDate from '@/utils/formatDate'

export default {
  name: 'UserProjects',
  components: {
    Table,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      fields: {
        url: { title: 'URL', processValue: (value) => this.invoiceURL(value), processLink: value => value, target: '_blank', width: '210px' },
        tariffId: { title: 'Plan', width: '125px' },
        total: { title: 'Total', width: '95px' },
        reason: { title: 'Fail Reason' },
        createdAt: { title: 'Date', processValue: formatDate, width: '210px' },
      },
    }
  },
  methods: {
    invoiceURL(value) {
      return value.replace(/.*((FLO|FMS)[0-9-]*).*/, '$1')
    },
  },
}
</script>
