<template>
  <page :title="pageTitle">
    <div class="support-user">
      <div class="block">
        <user-properties class="general" :user="user" @change="update" />
        <user-quota class="quota" :user="user" @updateRequired="update" />
      </div>
      <user-plan-actions :user="user" @change="update" />
      <user-subscriptions :user="user" @change="update" />
      <user-invoices :user="user" />
      <user-workspaces :user="user" />
      <user-projects :user="user" @rowClick="onProjectRowClick" />
      <user-collaboration-projects :user="user" @rowClick="onProjectRowClick" />
      <user-collaboration-workspaces :user="user" />
    </div>
  </page>
</template>

<script>
import axios from '@/utils/axios'

import UserCollaborationProjects from '@/components/UserCollaborationProjects'
import UserCollaborationWorkspaces from '@/components/UserCollaborationWorkspaces'
import UserInvoices from '@/components/UserInvoices'
import UserPlanActions from '@/components/UserPlanActions'
import UserProjects from '@/components/UserProjects'
import UserProperties from '@/components/UserProperties'
import UserQuota from '@/components/UserQuota'
import UserSubscriptions from '@/components/UserSubscriptions'
import UserWorkspaces from '@/components/UserWorkspaces'

export default {
  name: 'SupportUser',
  components: {
    UserCollaborationProjects,
    UserCollaborationWorkspaces,
    UserInvoices,
    UserPlanActions,
    UserProjects,
    UserProperties,
    UserQuota,
    UserSubscriptions,
    UserWorkspaces,
  },
  data() {
    return {
      user: {},
      trash: [],
    }
  },
  created() {
    this.update()
  },
  computed: {
    pageTitle() {
      return `User ${this.user.login || ''}`
    },
  },
  methods: {
    async update() {
      const { id } = this.$route.params
      const { data: user } = await axios.get(`/support/users/${id}`)
      this.user = user
    },
    onProjectRowClick(project) {
      this.$router.push({ name: 'SupportProject', params: { id: project.id } })
    },
  },
}
</script>

<style lang="sass" scoped>
.support-user
  .block
    display: flex
    justify-content: space-between
</style>
