<template>
  <page title="Projects">
    <div class="support-projects">
      <search-input class="search" debounce="200" @change="onQueryChange" placeholder="Search by id, title" />
      <Table :rows="filteredProjects" :fields="fields" @rowClick="onRowClick" />
    </div>
  </page>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import SearchInput from '@/components/SearchInput'
import Table from '@/components/Table'

export default {
  name: 'SupportProjects',
  components: {
    SearchInput,
    Table,
  },
  data() {
    return {
      query: '',
      fields: {
        title: { title: 'Title' },
        email: { title: 'Owner', width: '300px', },
        isActive: { title: 'Active', width: '90px', processValue: value => !value, isBoolean: true },
        isArchive: { title: 'Archived', width: '90px', isBoolean: true },
      },
    }
  },
  computed: {
    ...mapState('projects', ['projects']),
    normalizedQuery() {
      return this.query.toLowerCase()
    },
    filteredProjects() {
      return this.projects.all.filter(
        row => row.title.match(this.normalizedQuery) || row.id.match(this.normalizedQuery),
      )
    },
  },
  created() {
    this.getProjects()
  },
  methods: {
    ...mapActions('projects', ['getProjects']),
    async onRowClick(row) {
      this.$router.push({ name: 'SupportProject', params: { id: row.id } })
    },
    onQueryChange(value) {
      this.query = value
    },
  },
}
</script>

<style lang="sass" scoped>
.search
  margin-bottom: 32px
</style>
