<template>
  <div class="project-actions">
    <action-list v-if="project.id" v-role="'PROJECT_ACTIONS'" title="Project Actions">
      <action v-if="project.deleteUUID" @click="onRestoreClick">Restore</action>
      <action @click="onSpyglassClick">Spyglass</action>
    </action-list>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import axios from 'axios'

import Action from '@/components/Action'
import ActionList from '@/components/ActionList'
import { openConfirmationPopup } from '@/components/ConfirmationPopup'

export default {
  name: 'ProjectActions',
  components: {
    Action,
    ActionList,
  },
  props: {
    project: Object,
  },
  methods: {
    ...mapActions('projects', ['restoreProject']),
    onRestoreClick() {
      openConfirmationPopup({
        // eslint-disable-next-line quotes
        text: "Are you sure you want to restore this project and all of it's contents?",
      })
        .then(async () => {
          await this.restoreProject({ projectId: this.project.id, deleteUUID: this.project.deleteUUID })
          this.$emit('updateRequired')
        })
        .catch(() => {})
    },
    async onSpyglassClick() {
      const { data: token } = await axios.post(
        `${process.env.VUE_APP_BACKEND_URL}/support/spyglass-login`,
        { userId: this.project.owner.id },
        { withCredentials: true },
      )
      window.open(
        `${process.env.VUE_APP_APP_FRONTEND_URL}/support-login?token=${token}&redirectUrl=/projects/${this.project.id}`,
        '_blank',
      )
    },
  },
}
</script>
