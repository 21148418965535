<template>
  <div class="user-subscriptions">
    <Table v-if="user.subscriptions" title="User's Subscriptions" :rows="user.subscriptions" :fields="fields" collapsed>
      <template #rowActions="{ row }">
        <action v-if="notProduction" @click="openExpirePopup(row)">Expire</action>
        <action v-role="'TRIALS'" v-if="row.isTrial" @click="openDeleteTrialPopup(row)">Delete</action>
      </template>
      <template #titleActions>
        <loader :size="20" v-if="isLoading" />
        <action @click="refreshFSData" v-else>Fetch from FS</action>
      </template>
    </Table>
  </div>
</template>

<script>
import axios from 'axios'

import Action from '@/components/Action'
import Loader from '@/components/Loader'
import Table from '@/components/Table'
import { openConfirmationPopup } from '@/components/ConfirmationPopup'

import api from '@/utils/api'
import formatDate from '@/utils/formatDate'

export default {
  name: 'UserSubscriptions',
  components: {
    Action,
    Loader,
    Table,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      isLoading: false,
      fields: {
        externalSubscriptionId: {
          title: 'ID',
          processLink: this.getSubscriptionLink,
          target: '_blank',
          width: '225px',
        },
        tariffId: { title: 'Plan' },
        dateStart: { title: 'Start Date', processValue: formatDate, width: '130px' },
        dateEnd: { title: 'End Date', processValue: formatDate, width: '130px' },
        isTrial: { title: 'Trial', isBoolean: true, width: '90px' },
        closeReason: { title: 'Close reason' },
      },
    }
  },
  computed: {
    hasPaidSubscription() {
      return this.user.subscriptions.filter(({ tariffId }) => tariffId !== 'free').length > 0
    },
    notProduction() {
      return window.location.host !== 'maestro.flowmapp.com'
    }
  },
  methods: {
    getSubscriptionLink(subscriptionId) {
      return `https://app.fastspring.com/subscription/home.xml?mRef=Subscription%3A${subscriptionId}&cRef=_.AcquisitionTransaction._%2FAcquisitionTransaction%3ANF-RMXZeQ5mW9HlTPNOM0A%2F%7C_.AcquisitionSearch%2F%2F%2Fquery%3DZ3JlZ2dAeW95b2Rlc2lnbi5jb20`
    },
    openDeleteTrialPopup(row) {
      openConfirmationPopup({
        text: 'Are you sure you want to delete this subscription?',
      })
        .then(async () => {
          await api.deleteTrialSubscription({ id: row.id })
          this.$emit('change')
        })
        .catch(() => {})
    },
    openExpirePopup(row) {
      openConfirmationPopup({
        text: 'Are you sure you want to expire this subscription?',
      })
        .then(async () => {
          await api.expireSubscription({ id: row.id })
          this.$emit('change')
        })
        .catch(() => {})
    },
    async refreshFSData(e) {
      e.preventDefault()
      e.stopPropagation()
      this.isLoading = true
      try {
        await axios.post(
          `${process.env.VUE_APP_APP_BACKEND_URL}/support/refresh-fs-data`,
          { email: this.user.email },
          {
            withCredentials: true,
          },
        )
      } catch (e) {
        openConfirmationPopup({
          text: e.message,
        })
      }
      this.isLoading = false
      this.$emit('change')
    },
  },
}
</script>
