<template>
  <div class="table">
    <div class="title" v-if="title" @click="isCollapsed = !isCollapsed">
      <span>{{ title }} ({{ rows.length }})</span>
      <div v-show="!isCollapsed">
        <slot name="titleActions" />
      </div>
    </div>
    <table v-show="!isCollapsed">
      <thead>
        <tr class="header" @click="onRowClick(row)">
          <td
            class="column"
            v-for="(field, fieldName) of fields"
            :key="fieldName"
            :style="{ width: field.width || '100%' }"
            :data-column="fieldName"
          >
            {{ field.title }}
          </td>
        </tr>
      </thead>
      <table-row v-for="row of displayRows" :key="row.id" :row="row" :fields="fields" @click="onRowClick(row)">
        <template #rowActions="{ row }">
          <slot name="rowActions" :row="row" />
        </template>
      </table-row>
    </table>
    <loader v-if="isLoading && !isCollapsed" />
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import TableRow from '@/components/TableRow'

export default {
  name: 'Table',
  components: { Loader, TableRow },
  props: {
    title: String,
    rows: Array,
    fields: Object,
    collapsed: Boolean,
  },
  watch: {
    rows() {
      this.isLoading = false
    },
    isCollapsed() {
      this.isLoading = false
    },
  },
  data() {
    return {
      isLoading: true,
      isCollapsed: true,
    }
  },
  created() {
    this.isCollapsed = this.collapsed
    setTimeout(() => {
      this.isLoading = this.collapsed ? false : !this.rows.length
    })
  },
  computed: {
    displayRows() {
      return this.rows.slice(0, 100)
    },
  },
  methods: {
    async onRowClick(row) {
      this.$emit('rowClick', row)
    },
  },
}
</script>

<style lang="sass" scoped>
.table
  width: 100%
  font-size: 14px
  border-radius: 2px

  table
    table-layout: fixed
    width: 100%

.title
  text-align: center
  font-size: 18px
  padding: 20px
  cursor: pointer

.header
  position: relative
  height: 48px
  font-weight: 700
  background-color: #fff

  &:after
    content: ''
    position: absolute
    width: 100%
    left: 0
    bottom: 0
    border-bottom: 3px solid #c9e2fd

  .column
    padding: 0 24px
</style>
