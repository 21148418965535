<template>
  <tr class="row">
    <td class="cell key">{{ field.title || fieldName }}</td>
    <td class="cell">
      <a :target="field.target || ''" v-if="field.processLink" :href="field.processLink(value)">
        <span>
          {{ value }}
        </span>
      </a>
      <input v-else-if="field.isBoolean" type="checkbox" :checked="value" disabled />
      <span v-else>
        {{ value }}
      </span>
      <div class="actions">
        <slot name="actions" :propKey="fieldName" :propValue="value" />
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'PropertiesTableRow',
  components: {},
  props: {
    object: Object,
    field: Object,
    fieldName: String,
  },
  data() {
    return {}
  },
  computed: {
    value() {
      const rawValue = this.object[this.fieldName]
      return this.field.processValue?.(rawValue) || rawValue
    },
  },
  created() {},
}
</script>

<style lang="sass" scoped>
.row
  height: 40px
  background-color: #fff

  &:nth-of-type(2n)
    background-color: #f3f6fc

  &:hover
    .actions
      opacity: 1

.cell
  padding: 0 24px
  position: relative

  &.key
    font-weight: 700

  a
    text-decoration: none
    color: #0d87ff

.actions
    position: absolute
    display: flex
    justify-content: center
    align-items: center
    top: 0
    right: 0
    height: 100%
    background: #f8f9fc
    opacity: 0
</style>
