<template>
  <confirmation-popup-template :onConfirm="onConfirm" :onCancel="onCancel">
    <span>
      {{ text }}
    </span>
  </confirmation-popup-template>
</template>

<script>
import Vue from 'vue'

import ConfirmationPopupTemplate from '@/components/ConfirmationPopupTemplate'

const ConfirmationPopup = {
  name: 'ConfirmationPopup',
  components: {
    ConfirmationPopupTemplate,
  },
  props: {
    onConfirm: Function,
    onCancel: Function,
    text: String,
  },
}

export const openConfirmationPopup = ({ text }) => {
  return new Promise((resolve, reject) => {
    const ConfirmationPopupClass = Vue.extend(ConfirmationPopup)
    const instance = new ConfirmationPopupClass({
      propsData: {
        text,
        onConfirm: () => {
          instance.$destroy()
          return resolve()
        },
        onCancel: () => {
          instance.$destroy()
          return reject()
        },
      },
    })
    instance.$mount()
  })
}

export default ConfirmationPopup
</script>

<style lang="sass" scoped>
.confirmation-popup
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 100%

  .buttons
    display: flex
    justify-content: space-between
</style>
