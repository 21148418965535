<template>
  <portal v-if="isVisible" selector="#portal">
    <div class="popup">
      <div class="curtain" @click="onClickOutside" />
      <div class="content">
        <slot />
      </div>
    </div>
  </portal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { Portal } from '@linusborg/vue-simple-portal'

export default {
  name: 'Popup',
  components: {
    Portal,
  },
  props: {
    isVisible: Boolean,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState([]),
  },
  created() {},
  methods: {
    ...mapActions([]),
    onClickOutside() {
      this.$emit('close')
    }
  },
}
</script>

<style lang="sass" scoped>
.popup
  position: fixed
  z-index: 1
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center

  .curtain
    position: fixed
    z-index: -1
    width: 100vw
    height: 100vh
    background-color: black
    opacity: .8

  .content
    display: flex
    padding: 40px 20px
    min-width: 30vw
    min-height: 20vw
    background-color: #fff
    border-radius: 2px
</style>
