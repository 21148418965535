<template>
  <page :title="pageTitle">
    <div class="support-project">
      <project-properties :project="project" />
      <project-actions :project="project" @updateRequired="update" />
      <project-users :project="project" />
    </div>
  </page>
</template>

<script>
import axios from '@/utils/axios'

import ProjectActions from '@/components/ProjectActions'
import ProjectProperties from '@/components/ProjectProperties'
import ProjectUsers from '@/components/ProjectUsers'

export default {
  name: 'SupportProject',
  components: {
    ProjectActions,
    ProjectProperties,
    ProjectUsers,
  },
  data() {
    return {
      project: {},
    }
  },
  computed: {
    pageTitle() {
      return `Project ${this.project.title || ''}`
    },
  },
  created() {
    this.update()
  },
  methods: {
    async update() {
      const { id } = this.$route.params
      const { data } = await axios.get(`/support/projects/${id}`)
      this.project = data
    },
  },
}
</script>
