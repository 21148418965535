<template>
  <div class="project-properties">
    <properties-table v-if="project.id" :object="project" :fields="fields" />
  </div>
</template>

<script>
import PropertiesTable from '@/components/PropertiesTable'

import formatDate from '@/utils/formatDate'

export default {
  name: 'ProjectProperties',
  components: {
    PropertiesTable,
  },
  props: {
    project: Object,
  },
  data() {
    return {
      projectPropertiesDate: {
        created: true,
      },
      fields: {
        id: { title: 'ID' },
        title: { title: 'Title' },
        createdAt: { title: 'Created', processValue: formatDate },
        isArchive: { title: 'Archived', isBoolean: true },
        isActive: { title: 'Active', processValue: value => !value, isBoolean: true },
        owner: {
          title: 'Owner',
          processValue: value => value.email,
          processLink: () => this.$router.resolve({ name: 'SupportUser', params: { id: this.project.owner.id } })?.href,
        },
        workspace: { title: 'Worskspace', processValue: () => this.project.workspace.title },
      },
    }
  },
}
</script>
